import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout'
import H1 from '../components/H1'

const HowToEat = () => (
  <Layout>
    <H1>Jak spożywać miód</H1>
    <p>Najlepiej rano na czczo i wieczorem rozpuszczając wcześniej w małej ilości letniej wody.</p>
    <strong>Dawkowanie:</strong><br />
    Dzieci i młodzież do 16 lat 10-40 g, dorośli 40-60 g.<br />
    Powyższe ilości można zażywać jednorazowo lub w dwóch dawkach w ciągu dnia.<br />
    Opracowano na podstawie literatury pszczelarskiej i medycznej.
  </Layout>
)

export default HowToEat
